import typeSelect from './components/select/index.vue'
import {mapMutations} from "vuex";
export default {
  props:{
    info:{
      type: Object,
      default: ()=> {}
    }
  },
  components:{
    typeSelect
  },
  name: "product",
  data(){
    return{
      selectedType: {},
      list:[
        {
          id:0,
          name:'helo'
        },{
          id:1,
          name:'hello'
        },{
          id:2,
          name:'helllo'
        },
      ]
    }
  },
  created() {
    if(this.info.productTypes.data.length)
    this.selectedType = this.info.productTypes.data[0]
  },
  methods:{
    ...mapMutations({
      addToBasket:`basket/ADD_ITEM_TO_BASKET`
    }),
    addBasket(){
      if(this.info.productTypes.data.length){
        this.addToBasket({
          select_count: 1,
          select_type: this.selectedType,
          ...this.info
        })
        this.$toasted.success(this.$t('successAddBasket'));
      }else{
        this.addToBasket({
          select_count: 1,
          ...this.info
        })
        this.$toasted.success(this.$t('successAddBasket'));
      }
    }
  }
}