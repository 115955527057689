import {mapActions, mapGetters, mapMutations} from "vuex";
import Banner from './components/banner/index.vue'
import Product from '@/components/product/index.vue'
import Product2 from '@/components/product2/index.vue'
import {Carousel, Slide} from 'vue-carousel';
import {globalLoader} from '@/helpers/variables/index';

import staticContent from '@/components/static-content/index.vue'


export default {
  name: "home",
  components: {
    Banner, Product,
    Carousel, Slide,
    Product2, staticContent,

  },
  data() {
    return {
      videoStatus: false,

    }
  },
  metaInfo() {
    if (this.home) {
      return {
        title: `${this.home.title} Alphascience`,
        meta: [
          {n: 'description', c: this.home.metaDescription, id: 'description'},
          {n: 'keywords', c: this.home.metaKeywords, id: 'keywords'},
          {n: 'og:title', c: this.home.metaTitle, id: 'og:title'},
          {n: 'og:description', c: this.home.metaDescription, id: 'og:description'},
        ]
      }
    }
  },
  created() {
    this.instagramAuth()
    if (this.$route.query.hasOwnProperty('token') && this.$route.query.hasOwnProperty('email')) {
      this.changeLoginPopup(false)
      this.changeRestorePopup(true);
    }
    if (!this.isAuthenticated && this.$route.query.hasOwnProperty('email') && !this.$route.query.hasOwnProperty('token')) {
      this.changeRestorePopup(false);
      this.changeLoginPopup(true)
    }

    if (!this.isAuthenticated && !this.$route.query.hasOwnProperty('email') && !this.$route.query.hasOwnProperty('token')) {
      this.changeRestorePopup(false);
      this.changeLoginPopup(true)
    }


    if (this.$route.query.hasOwnProperty('payment')) {
      this.changePaymentInfoPopup(true)
      if (this.$route.query.payment === 'success') {
        this.resetBasket()
      } else {
        this.$router.push({name: 'buy-flow-order'}).catch(() => {
        })
      }
    }
    globalLoader(true)
    this.fetchHomePage('home').then(() => {
      this.fetchNewProducts().then(() => {
        this.fetchBestSellers().then(() => {
          // this.fetchInstagramPosters().then(()=>{
          if (this.home) {
            this.$refs.video.src = this.home.video
          }
          globalLoader(false)
          // })
        })
      })
    })


  },
  computed: {
    ...mapGetters({
      home: 'pages/page',
      newProducts: `home/newProducts`,
      bestSellers: `home/bestSellers`,
      isAuthenticated: 'auth/isAuthenticated',
      favourites: 'favorites/whichList',
      headerMenu: 'setting/headerMenu'
    }),
    bestSellerItems() {
      if (this.isAuthenticated) {
        if (!this.bestSellers) return []
        if (!this.favourites) return []
        return this.bestSellers.map(e => {
          const item = this.favourites.find(t => t.productId === e.id)
          e.selected = !!item
          return e
        })
      } else {
        return this.bestSellers
      }
    },
    newItemsList() {
      if (this.isAuthenticated) {
        if (!this.newProducts) return []
        if (!this.favourites) return []
        return this.newProducts.map(e => {
          const item = this.favourites.find(t => t.productId === e.id)
          e.selected = !!item
          return e
        })
      } else {
        return this.newProducts
      }
    }
  },
  methods: {
    ...mapMutations({
      changeRestorePopup: 'popups/CHANGE_RESTORE_POPUP',
      changeInstagramPopup: 'popups/CHANGE_INSTAGRAM_POPUP',
      changePaymentInfoPopup: 'popups/CHANGE_PAYMENT_INFO_POPUP',
      resetBasket: 'basket/RESET_BASKET',
      changeLoginPopup: 'popups/CHANGE_LOGIN_POPUP',

    }),
    ...mapActions({
      login: 'auth/GET_TOKEN',
      fetchHomePage: 'pages/GET_PAGE',
      fetchNewProducts: 'home/GET_NEW_PRODUCTS',
      fetchBestSellers: 'home/GET_BESTSELLER',
      fetchInstagramPosters: 'home/GET_INSTAGRAM_POSTERS',
      instagramAuth: 'instagram/GET_CODE'
    }),
    play() {
      this.$refs.video.play()
      this.videoStatus = true
    },
    pause() {
      this.$refs.video.pause()
      this.videoStatus = false
    },
    carouselNav($event, direction) {
      const carousel = this.$refs['carousel1'];
      carousel.advancePage(direction);
    },
    carouselNav2($event, direction) {
      const carousel = this.$refs['carousel2'];
      carousel.advancePage(direction);
    },
    redirectInstagram() {
      window.open('https://www.instagram.com/', '_blank');
    }
  }
}
