import {mapActions, mapGetters, mapMutations} from "vuex";
import { Carousel, Slide } from 'vue-carousel';
export default {
  name: "banner",
  data(){
    return{
    }
  },
  components:{
    Carousel,
    Slide
  },
  created() {
    this.fetchBanners()
  },
  computed:{
    ...mapGetters({
      banners:`home/bannersList`,
      bannerLoading:`home/bannerLoading`
    })
  },
  methods:{
    ...mapMutations({
    }),
    ...mapActions({
      fetchBanners:'home/GET_BANNERS'
    }),
    carouselNav($event, direction) {
      const carousel = this.$refs['carousel'];
      carousel.advancePage(direction);
    }
  }
}
