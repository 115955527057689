import {directive as onClickaway} from 'vue-clickaway';
import {mapActions, mapMutations} from "vuex";

export default {
    directives: {
        onClickaway: onClickaway,
    },
    props: {
        type: {
            type: String,
            default: 'objectData'
        },
        value: {},
        label: {
            type: String,
            default: ''
        },
        open: {
            type: Boolean,
            default: false
        },
        progress: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        },
        itemValue: {
            type: String,
            default: 'default'
        },
        itemTitle: {
            type: String,
            default: 'count'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        list: {
            type: Array,
            default: () => [],
        }
    },
    mounted() {
    },
    data() {
        return {
            resetItem: false,
            page: 0,
            openSelect: false,
            selectedItem: this.value,
            selectedFilters: {
                category: '',
                type: {
                    name: this.$t('toDefault'),
                    key: 'default'
                }
            }
        }
    },

    created() {
        this.selectedItem = this.value;

    },
    methods: {
        ...mapActions({}),
        ...mapMutations({}),
        changeSelect(item) {
            if (this.$route.query.hasOwnProperty('id'))
                this.$router.replace({name: 'catalog', query: ''})

            this.resetItem = false;
            setTimeout(() => {
                this.openSelect = false;
            }, 1)

            this.selectedItem = item;
            this.$emit('input', item);

        },
        away: function () {
            this.openSelect = false
        },
    }
}
